import Meeting from '../pages/Layout/Meeting';
import Home from '../pages/Layout/Home';
import { BrowserRouter as Router, Route, } from "react-router-dom";

export default function RootNavigation() {

  return (
    <Router>
      <Route path="/">
        <Home />
      </Route>
      <Route path="/meeting/:userId/:userName/:roomId/:meetingId">
        <Meeting />
      </Route>
    </Router>
  );
}
