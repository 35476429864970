import { useEffect, useState } from 'react';
import { HMSPrebuilt } from '@100mslive/roomkit-react';
import { useRouteMatch } from "react-router-dom";
import appapi from '../../api/apiClient';
import '../../App.css'
function Meeting() {

    const match: any = useRouteMatch();
    const [isLoading, setLoading] = useState(true);
    const [isActiveSession, setActiveSession] = useState('');
    const { roomId, userId, userName, meetingId } = match.params;

    const onLeaveMeeting = () => {
        const host = window.location.hostname;
        const isDev = host === 'meet-dev.gaayak.in';
        window.location.replace(isDev ? "https://app-dev.gaayak.in/" : "https://app.gaayak.in/");
    }

    useEffect(() => {
        if (meetingId) {
            getMeetingStatus();
        }
    }, [meetingId]);

    const getMeetingStatus = async () => {
        const response = await appapi.get(`schedules/isSessionActive/${meetingId}`);
        if (response.ok) {
            const data: any = response.data;
            setLoading(false);
            if (data.status === '7400') {
                setActiveSession('')
            } else {
                setActiveSession(data.message);
            }
        }
    }


    return (
        <div style={{ textAlign: "center", flex: 1 }}>
            <div style={{ height: "100vh" }}>
                {!isLoading && !isActiveSession ? <HMSPrebuilt
                    roomCode={roomId}
                    onLeave={onLeaveMeeting}
                    options={{
                        userId: userId,
                        userName: userName,
                    }}

                /> : !isLoading && isActiveSession ? <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center', }}>
                    <p style={{ fontSize: 24 }}>{isActiveSession}</p>
                    <button className='button-bg goBackBtn' onClick={onLeaveMeeting}>Go Back</button>
                </div> : <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <p style={{ fontSize: 24 }}>Loading...</p>
                </div>}
            </div>
        </div>
    );
}

export default Meeting;